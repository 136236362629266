import { HttpClient } from '@angular/common/http';
import { JOBS_CONFIG } from '../../@config/api';
import * as i0 from "@angular/core";
import * as i1 from "@angular/common/http";
export class JobsService {
    constructor(http) {
        this.http = http;
        this.CONFIG = JOBS_CONFIG;
    }
    getPreviousJobsList(sslgId) {
        return this.http.post(this.CONFIG.getList, { sslgId });
    }
    getJobInfo(sslgId, id) {
        return this.http.post(this.CONFIG.getItemInfo, { sslgId, id });
    }
    saveJob(sslgId, result, current) {
        //return this.http.post<any>(this.CONFIG.saveItem, {sslgId, result, current});
        return this.http.post(this.CONFIG.saveItem, result);
    }
    deleteJob(sslgId, id) {
        return this.http.post(this.CONFIG.deleteJob, { sslgId, id });
    }
    setWorkHistoryData(data, type) {
        localStorage.setItem(type, JSON.stringify(data));
    }
    getWorkHistoryData(type) {
        const parsedData = localStorage.getItem(type);
        return JSON.parse(parsedData);
    }
    clearJobLocalStorage() {
        localStorage.removeItem('currentStep');
        localStorage.removeItem('workHistory');
        localStorage.removeItem('jobOverView');
        localStorage.removeItem('dayToDayJob');
        localStorage.removeItem('exertionalDemands');
        localStorage.removeItem('liftRequirement');
        localStorage.removeItem('supervisoryDuties');
    }
}
JobsService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function JobsService_Factory() { return new JobsService(i0.ɵɵinject(i1.HttpClient)); }, token: JobsService, providedIn: "root" });
