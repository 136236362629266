import * as tslib_1 from "tslib";
import { OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { LoadingService } from 'src/@util/loading/loading.service';
import { DateService } from 'src/app/@core/functions/date.service';
import { JobsService } from 'src/app/@core/jobs/jobs.service';
import { LastJobService } from 'src/app/@core/jobs/lastJob.service';
export class WorkHistoryPrintComponent {
    constructor(loading, route, jobService, dateService, lastJobService) {
        this.loading = loading;
        this.route = route;
        this.jobService = jobService;
        this.dateService = dateService;
        this.lastJobService = lastJobService;
    }
    ngOnInit() {
        this.route.paramMap.subscribe((j) => tslib_1.__awaiter(this, void 0, void 0, function* () {
            this.initDataSource();
        }));
    }
    initDataSource() {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            const sslgId = localStorage.getItem('sslgId');
            const prevJobId = localStorage.getItem('prevJobId');
            yield this.jobService.getJobInfo(sslgId, prevJobId).subscribe(data => {
                const formattedData = data;
                if (formattedData) {
                    formattedData.startDate = this.dateService.setDateFormat(formattedData.startDate);
                    formattedData.endDate = this.dateService.setDateFormat(formattedData.endDate);
                }
                this.jobOverview = formattedData;
            });
            yield this.lastJobService.getDetails(sslgId, prevJobId).subscribe(data => {
                this.detailsEntity = data;
            });
            yield this.lastJobService.getAdditionalInfo(sslgId, prevJobId).subscribe(data => {
                this.additionalInfoEntity = data;
            });
        });
    }
    formatCurr(value) {
        return new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD' }).format(value);
    }
    print() {
        window.print();
    }
    formatExertional(value) {
        if (value) {
            switch (value) {
                case 0:
                    return 'None';
                case 0.5:
                    return value + ' hours';
                case 1:
                    return value + ' hour';
                case 2:
                    return value + ' hours (not very often)';
                case 4:
                    return value + ' hours (part of the day)';
                case 6:
                    return value + ' hours (most of the day)';
                case 8:
                    return value + ' hours or more';
                default:
                    return value + ' hours';
            }
        }
    }
    formatDate(date) {
        if (date) {
            const dateObject = new Date(date);
            const day = String(dateObject.getDate()).padStart(2, '0');
            const month = String(dateObject.getMonth() + 1).padStart(2, '0');
            const year = dateObject.getFullYear();
            return `${month}/${day}/${year}`;
        }
        else {
            return '';
        }
    }
}
