import * as tslib_1 from "tslib";
import { HttpClient, HttpParams } from '@angular/common/http';
import { DASHBOARD_CONFIG, REFERRAL_CONFIG } from '../@config/api';
import { map } from 'rxjs/operators';
import { AdvisorsService } from './advisors.service';
import { ClientsService } from './clients.service';
import { AdjustersService } from './adjusters.service';
import { SystemUsersService } from "./system-users.service";
import { StatusService } from "./status.service";
import { Subject } from "rxjs";
import { UserInfoService } from "./user-info.service";
import { DateService } from "./functions/date.service";
import { PERMISSIONS } from '../@config/permissions';
import * as i0 from "@angular/core";
import * as i1 from "@angular/common/http";
import * as i2 from "./adjusters.service";
import * as i3 from "./system-users.service";
import * as i4 from "./advisors.service";
import * as i5 from "./clients.service";
import * as i6 from "./status.service";
import * as i7 from "./functions/date.service";
import * as i8 from "./user-info.service";
export class ReferralService {
    constructor(http, adjusterService, systemUserService, advisorService, clientService, statusService, dateService, userInfoService) {
        this.http = http;
        this.adjusterService = adjusterService;
        this.systemUserService = systemUserService;
        this.advisorService = advisorService;
        this.clientService = clientService;
        this.statusService = statusService;
        this.dateService = dateService;
        this.userInfoService = userInfoService;
        this.claim = new Subject();
        this.updateClaimInfo = new Subject();
        this.isClaimHeaderAttention = new Subject();
        this.config = REFERRAL_CONFIG;
        this.configHeader = DASHBOARD_CONFIG;
    }
    getList(type, username) {
        let sslgId = this.getUserName();
        if (username && PERMISSIONS.HIGHER_EXEC_LEVEL_1.includes(this.userInfoService.getCurrentUserRole()))
            sslgId = username;
        return this.http.post(this.config.CLAIMANT.getList, {
            userName: sslgId,
            type: type
        });
        //let param = new HttpParams();
        //return this.http.get<Claimant[]>(this.config.CLAIMANT.getClaimants, {params: param});
    }
    getConsolidator(username) {
        let sslgId = this.getUserName();
        if (username && PERMISSIONS.HIGHER_EXEC_LEVEL_1.includes(this.userInfoService.getCurrentUserRole()))
            sslgId = username;
        return this.http.post(this.configHeader.header.consolidator, { userName: sslgId });
    }
    getAll(archived) {
        let param = new HttpParams();
        if (archived != undefined) {
            param = param.append('archived', archived);
        }
        return this.http.get(this.config.CLAIMANT.getClaimants, { params: param });
    }
    getClaimantLevelDetails(sslgId) {
        return this.http.get(this.config.CLAIMANT.getClaimantLevelDetails(sslgId));
    }
    getById(sslgId) {
        return this.http.get(this.config.REFERRAL.getById(sslgId));
    }
    addNew(val) {
        return this.http.post(this.config.CLAIMANT.addClaimant, val);
    }
    setAcquisition(sslgId, val) {
        return this.http.post(this.config.REFERRAL.setAcquisition(sslgId), val);
    }
    // setSummary(sslgId, val: Summary) { //@deprecated
    // 	return this.http.post(this.config.REFERRAL.setSummary(sslgId), val);
    // }
    getClients() {
        return this.clientService.getUsersList().pipe(map((data) => {
            return data.map(j => {
                let name = 'Fill Client Name';
                if (j.clientBasic && j.clientBasic.firstName && j.clientBasic.lastName) {
                    name = j.clientBasic.firstName + ' ' + j.clientBasic.lastName;
                }
                return {
                    value: j.username,
                    label: name
                };
            });
        }));
    }
    getReferrals() {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            const rez = yield this.advisorService.getUsersList().pipe(map((data) => this.mapUser(data))).toPromise();
            const managers = yield this.getManagers().toPromise();
            rez.push(...managers);
            return rez.sort((a, b) => {
                const aLabel = a.label.toLowerCase();
                const bLabel = b.label.toLowerCase();
                if (aLabel < bLabel) {
                    return -1;
                }
                if (aLabel > bLabel) {
                    return 1;
                }
                return 0;
            });
        });
    }
    getManagers() {
        return this.adjusterService.getUsersList().pipe(map((data) => this.mapUser(data).sort((a, b) => {
            const aLabel = a.label.toLowerCase();
            const bLabel = b.label.toLowerCase();
            if (aLabel < bLabel) {
                return -1;
            }
            if (aLabel > bLabel) {
                return 1;
            }
            return 0;
        })));
    }
    mapUser(data) {
        return data.map(j => {
            return {
                value: j.username,
                label: !(j.personal && j.personal.firstName && j.personal.lastName) ?
                    'Fill Name' : j.personal.firstName + ' ' + j.personal.lastName
            };
        });
    }
    mapUserStaff(data) {
        return data.map(j => {
            let thisLabel = '';
            if (!(j.personal && j.personal.firstName && j.personal.lastName)) {
                thisLabel = 'Fill Name';
            }
            else {
                thisLabel = j.personal.firstName + ' ' + j.personal.lastName;
            }
            if (j.personal && j.personal.title) {
                thisLabel = thisLabel + ', ' + j.personal.title;
            }
            else {
                thisLabel = thisLabel + ' ';
            }
            if (!j.contact || !j.contact.email) {
                thisLabel = thisLabel + '';
            }
            else {
                thisLabel = thisLabel + ' ~ ' + j.contact.email;
            }
            return {
                value: j.username,
                label: thisLabel
            };
        });
    }
    getCurrent() {
        return this.claim;
    }
    setCurrent(param) {
        this.current = param;
        this.claim.next(param);
    }
    getNotes(sslgId, type) {
        const url = this.config.NOTES.getAll(sslgId, type);
        return this.http.get(url);
    }
    getNoteById(sslgId, id) {
        return this.http.get(this.config.NOTES.getById(sslgId, id));
    }
    saveNote(sslgId, data) {
        return this.http.post(this.config.NOTES.save(sslgId), data);
    }
    deleteNoteById(sslgId, id) {
        return this.http.delete(this.config.NOTES.getById(sslgId, id), { responseType: 'text' });
    }
    bulkDeleteNotesByIds(sslgId, ids) {
        const url = this.config.NOTES.bulkDeleteByIds(sslgId);
        return this.http.request('delete', url, {
            body: ids,
            responseType: 'text'
        });
    }
    addFollowUp(sslgId, data) {
        return this.http.post(this.config.FOLLOW_UPS.addPending(sslgId), data);
    }
    getFollowUpsPending(sslgId) {
        return this.http.get(this.config.FOLLOW_UPS.getPending(sslgId));
    }
    getStatusAndFollowUpsPending(sslgId) {
        let userName = this.getUserName();
        return this.http.get(this.config.FOLLOW_UPS.getPendingAndStatus(sslgId, userName));
    }
    setFollowUpCompleted(data) {
        return this.http.post(this.config.FOLLOW_UPS.setCompleted(data.sslgId), data);
    }
    getFollowUpsCompleted(sslgId) {
        return this.http.get(this.config.FOLLOW_UPS.getCompleted(sslgId));
    }
    getExecutiveStaff() {
        return this.systemUserService.getUsersList(null).pipe(map((data) => this.mapUser(data)));
    }
    getExecutiveStaffUsers() {
        return this.systemUserService.getUsersList(null); //.pipe(map((data: User[]) => this.mapUserStaff(data)));
    }
    getFollowUp(sslgId, followUpId) {
        return this.http.get(this.config.FOLLOW_UPS.getById(sslgId, followUpId));
    }
    createClaimant(data) {
        return this.http.post(this.config.CLAIMANT.create, data);
    }
    getNewSslgId(referredBy) {
        return this.http.get(this.config.CLAIMANT.getNewSslgId(referredBy), { responseType: 'text' });
    }
    saveSSARules(sslgId, data) {
        return this.http.post(this.config.REFERRAL.SSARules(sslgId), data);
    }
    saveSSLGStaff(sslgId, data) {
        return this.http.post(this.config.REFERRAL.SSLGStaff(sslgId), data);
    }
    saveDisablingConditions(sslgId, disCond, disCondClaim) {
        return this.http.post(this.config.REFERRAL.DisablingConditions(sslgId), {
            disablingConditions: disCond,
            disablingConditionsClaimant: disCondClaim
        });
    }
    saveIncome(sslgId, data) {
        return this.http.post(this.config.REFERRAL.Income(sslgId), data);
    }
    getSSARules(sslgId) {
        return this.http.get(this.config.REFERRAL.SSARules(sslgId));
    }
    getSSLGStaff(sslgId) {
        return this.http.get(this.config.REFERRAL.SSLGStaff(sslgId));
    }
    getSSLGStaffForUser(sslgId) {
        return this.http.get(this.config.REFERRAL.SSLGStaffForUser(sslgId, this.getUserName()));
    }
    getDisablingConditions(sslgId) {
        return this.http.get(this.config.REFERRAL.DisablingConditions(sslgId));
    }
    getIncome(sslgId) {
        return this.http.get(this.config.REFERRAL.Income(sslgId));
    }
    getPeriod(param) {
        let req = param;
        if (!req) {
            req = { startDate: null, endDate: null };
        }
        req.startDate = this.dateService.getDateWithoutHours(req.startDate);
        req.endDate = this.dateService.getDateWithoutHours(req.endDate);
        return this.http.post('/api/service/date-time/period', req);
    }
    getPolicyholderCodes(sslgId) {
        return this.http.get(this.config.CLAIMANT.policyHolderCodes(sslgId));
    }
    saveDependents(sslgId, data) {
        return this.http.post(this.config.REFERRAL.Dependents(sslgId), data);
    }
    getDependents(sslgId) {
        return this.http.get(this.config.REFERRAL.Dependents(sslgId));
    }
    getClaimantFlag(sslgId) {
        return this.http.get(this.config.REFERRAL.ClaimantFlag(sslgId));
    }
    setClaimantFlag(sslgId, data) {
        return this.http.post(this.config.REFERRAL.ClaimantFlag(sslgId), data);
    }
    getClaimantInfo(sslgId) {
        return this.http.get(this.config.CLAIMANT.getInfo(sslgId));
    }
    getClaimantPdfInfo(sslgId) {
        return this.http.get(this.config.CLAIMANT.getInfoForPdf(sslgId));
    }
    getFollowUpsCodes(sslgId) {
        return this.http.get(this.config.FOLLOW_UPS.getCodes(sslgId));
    }
    setClaimantFlagIncome(sslgId, data) {
        return this.http.post(this.config.REFERRAL.setClaimantFlagIncome(sslgId), data);
    }
    setClaimantOtherIncome(sslgId, data) {
        return this.http.post(this.config.REFERRAL.setClaimantOtherIncome(sslgId), data);
    }
    getClaimantFlagIncome(sslgId) {
        return this.http.get(this.config.REFERRAL.getClaimantFlagIncome(sslgId));
    }
    getClaimantOtherIncome(sslgId) {
        return this.http.get(this.config.REFERRAL.getClaimantOtherIncome(sslgId));
    }
    getUserName() {
        return this.userInfoService.getCurrentUserName();
    }
    updateClaimHeaderAttention(sslgId) {
        this.getClaimantLevelDetails(sslgId).subscribe(val => {
            this.isClaimHeaderAttention.next(val.attention);
        });
    }
}
ReferralService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function ReferralService_Factory() { return new ReferralService(i0.ɵɵinject(i1.HttpClient), i0.ɵɵinject(i2.AdjustersService), i0.ɵɵinject(i3.SystemUsersService), i0.ɵɵinject(i4.AdvisorsService), i0.ɵɵinject(i5.ClientsService), i0.ɵɵinject(i6.StatusService), i0.ɵɵinject(i7.DateService), i0.ɵɵinject(i8.UserInfoService)); }, token: ReferralService, providedIn: "root" });
