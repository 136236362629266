import { HttpClient, HttpRequest, HttpResponse } from '@angular/common/http';
import { JOBS_CONFIG } from '../../@config/api';
import { filter } from 'rxjs/operators';
import * as i0 from "@angular/core";
import * as i1 from "@angular/common/http";
export class LastJobService {
    constructor(http) {
        this.http = http;
        this.config = JOBS_CONFIG;
    }
    saveDescription(sslgId, data) {
        return this.http.post(this.config.LAST.DescriptionSave(sslgId), data);
    }
    getDescription(sslgId) {
        return this.http.get(this.config.LAST.Description(sslgId));
    }
    saveDetails(sslgId, data) {
        return this.http.post(this.config.LAST.DetailsSave(sslgId), data);
    }
    getDetails(sslgId, previousJobId) {
        return this.http.get(this.config.LAST.Details(sslgId, previousJobId));
    }
    saveAdditionalInfo(sslgId, data) {
        return this.http.post(this.config.LAST.AdditionalInfoSave(sslgId), data);
    }
    getAdditionalInfo(sslgId, previousJobId) {
        return this.http.get(this.config.LAST.AdditionalInfo(sslgId, previousJobId));
    }
    saveJob(data) {
        return this.http.post(this.config.saveJob, data);
    }
    sendLastJobByEmail(emails, prevJobId, sslgId) {
        const data = { emails: emails, prevJobId: prevJobId, sslgId: sslgId };
        return this.request(this.config.sendEmail, data);
    }
    request(url, data) {
        const req = new HttpRequest('POST', url, data, { responseType: 'arraybuffer' });
        return this.http.request(req).pipe(filter(event => event instanceof HttpResponse));
    }
}
LastJobService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function LastJobService_Factory() { return new LastJobService(i0.ɵɵinject(i1.HttpClient)); }, token: LastJobService, providedIn: "root" });
